
import React, { useRef, useEffect, useState } from 'react';
import './App.css';
import { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import ReactMarkdown from 'react-markdown';


firebase.initializeApp({
  apiKey: "AIzaSyAV_gJtr1REIuF9rT4SyJGxcrgXjIqP0VY",
  authDomain: "zeta-portal-360923.firebaseapp.com",
  projectId: "zeta-portal-360923",
  storageBucket: "zeta-portal-360923.appspot.com",
  messagingSenderId: "868273293239",
  appId: "1:868273293239:web:ce2d1cde371069eeb58a32",
  measurementId: "G-HZLNT30T9Y"
})

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {
  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>
        <div className="header-content">
          <h1>MoreGpt4</h1>
          {/* <span className="sub-heading">Unlimited gpt4</span>  */}
        </div>
        <div className="header-buttons">
          <DeleteMessages />
          <SignOut />
        </div>
      </header>

      <section>
        {user ? <ChatRoom /> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  const signInWithFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <>
      <button className="sign-in with-google" onClick={signInWithGoogle}>Sign in with Google</button>
      {/* <button className="sign-in with-facebook" onClick={signInWithFacebook}>Sign in with Facebook</button> */}
      <p className="warning-text">Contact me at Tomhqq@gmail.com for help.</p>
    </>
  )
}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
}

function DeleteMessages() {
  const [user] = useAuthState(auth);
  const messagesRef = firestore.collection('messages');
  
  const deleteAllMessages = async () => {
    const querySnapshot = await messagesRef.get();
    querySnapshot.forEach((doc) => {
      doc.ref.delete();
    });
  };

  return user && (
    <button className="delete-messages" onClick={deleteAllMessages}>Delete All</button>
  )
}

function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');

  const [user] = useAuthState(auth);
  const query = messagesRef.where('uid', '==', user.uid).orderBy('createdAt');
  const [messages] = useCollectionData(query, { idField: 'id' });
  const [formValue, setFormValue] = useState('');
  const [sendDisabled, setSendDisabled] = useState(false);

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL: 'https://play-lh.googleusercontent.com/MkDTZYlBz5l9DIMGbNHc7Yoc6gE5U5TdlVk-rPAv86mkXuUiKIUPHIrEKvYTJHsGHPw=w526-h296-rw',
      sent: true
    })

    setFormValue('');

    setSendDisabled(true);
    setTimeout(() => {
      setSendDisabled(false);
    }, 6000);
  }

  return (<>
    <main>
      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
      <span ref={dummy}></span>
    </main>
    <form onSubmit={sendMessage}>
      <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Ask a question" />
      <button type="submit" disabled={!formValue || sendDisabled}>🕊️</button>
    </form>
  </>)
}

function ChatMessage(props) {
  const { text, uid, photoURL, sent } = props.message;
  const messageClass = sent ? 'sent' : 'received';

  return (
    <>
      <div className={`message ${messageClass}`}>
        <img src={photoURL} />
        {sent ? (
          <p>{text}</p>
        ) : (
          <ReactMarkdown className="markdown">{text}</ReactMarkdown>
        )}
      </div>
    </>
  );
}

export default App;